import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

let gallerieFlex = document.querySelectorAll('.article__images_flex');

if (gallerieFlex) {
    gallerieFlex.forEach(gallery => {
        var msnry = new Masonry(gallery, {
            itemSelector: 'img',
            transitionDuration: 0
        });

        imagesLoaded(gallery).on('progress', function () {
            // layout Masonry after each image loads
            msnry.layout();
        });
    });
}