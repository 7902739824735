// Importare i moduli
// import './modules/nomemodulo';
import './modules/article__images';
import './modules/gallery';
import './modules/emails';
import './modules/nav';
// import './modules/animator';


// Rimozione classe no-js
document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');