const nav = document.querySelector('.nav');
const hamburger = document.querySelector('.header__hamburger');
const darkener = document.querySelector('.darkener');
const body = document.body;

const navStyles = window.getComputedStyle(nav);
const navTransDuration = parseFloat(navStyles.transitionDuration);


var isOpen = false;

function openNav() {
    body.classList.add('body_navOpen');
    hamburger.classList.add('is-active');
    isOpen = true;
}
function closeNav() {
    body.classList.remove('body_navOpen');
    hamburger.classList.remove('is-active');

    darkener.style.width = '100%';
    darkener.style.height = '100%';
    setTimeout(() => {
        darkener.removeAttribute('style');
    }, navTransDuration * 1000);

    isOpen = false;
}
hamburger.addEventListener('click', function () {
    if (isOpen == false) {
        openNav();
    } else {
        closeNav();
    }
});

darkener.addEventListener('click', function () {
    if (isOpen == true) {
        closeNav();
    }
});

