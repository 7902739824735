import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import PhotoSwipeLightbox from "/node_modules/photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipe from "/node_modules/photoswipe/dist/photoswipe.esm";

let galleria = document.querySelector('.gallery');

if (galleria) {
    var msnryGalleria = new Masonry(galleria, {
        itemSelector: '.gallery__item',
        gutter: '.gallery_gutter',
        transitionDuration: 0
    });

    imagesLoaded(galleria).on('progress', function () {
        // layout Masonry after each image loads
        msnryGalleria.layout();
    });

    const lightbox = new PhotoSwipeLightbox({
        gallery: galleria,
        children: '.gallery__item',
        pswpModule: PhotoSwipe
    });

    // Aggiungo elementi UI
    lightbox.on('uiRegister', function () {
        // Aggiungo pulsante download
        lightbox.pswp.ui.registerElement({
            name: 'download-button',
            order: 8,
            isButton: true,
            tagName: 'a',

            // SVG with outline
            html: {
                isCustomSVG: true,
                inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
                outlineID: 'pswp__icn-download'
            },

            // Or provide full svg:
            // html: '<svg width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" class="pswp__icn"><path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" /></svg>',

            // Or provide any other markup:
            // html: '<i class="fa-solid fa-download"></i>' 

            onInit: (el, pswp) => {
                el.setAttribute('download', '');
                el.setAttribute('target', '_blank');
                el.setAttribute('rel', 'noopener');

                pswp.on('change', () => {
                    console.log('change');
                    el.href = pswp.currSlide.data.src;
                });
            }
        });
        // Aggiungo caption foto
        // lightbox.pswp.ui.registerElement({
        //     name: 'custom-caption',
        //     order: 9,
        //     isButton: false,
        //     appendTo: 'root',
        //     html: 'Caption text',
        //     onInit: (el, pswp) => {
        //         lightbox.pswp.on('change', () => {
        //             const currSlideElement = lightbox.pswp.currSlide.data.element;
        //             let captionHTML = '';
        //             if (currSlideElement) {
        //                 const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
        //                 if (hiddenCaption) {
        //                     // get caption from element with class hidden-caption-content
        //                     captionHTML = hiddenCaption.innerHTML;
        //                 } else {
        //                     // get caption from alt attribute
        //                     captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
        //                 }
        //             }
        //             el.innerHTML = captionHTML || '';
        //         });
        //     }
        // });
    });

    lightbox.init();
}