const emailLinks = document.querySelectorAll('.contacts__contattoList-email');

emailLinks.forEach(link => {
    let encodedEmail = link.getAttribute('data-hash');
    let decodedEmail = window.atob(encodedEmail);
    link.innerHTML = decodedEmail;
    link.addEventListener('click', function (e) {
        let subject = 'Richiesta di contatto dal sito ufficiale di Anna Safroncik';
        window.location = 'mailto:' + decodedEmail + '?subject=' + subject;
    })
});